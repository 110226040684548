
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf');
	font-display: swap;
}

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #202C39;
	--ion-color-primary-rgb: 32,44,57;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1c2732;
	--ion-color-primary-tint: #36414d;

	--ion-color-secondary: #202C39;
	--ion-color-secondary-rgb: 32,44,57;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #1c2732;
	--ion-color-secondary-tint: #36414d;

	--ion-color-tertiary: #202C39;
	--ion-color-tertiary-rgb: 32,44,57;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #1c2732;
	--ion-color-tertiary-tint: #36414d;

	--ion-color-success: #2B8B4E;
	--ion-color-success-rgb: 43,139,78;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #267a45;
	--ion-color-success-tint: #409760;

	--ion-color-warning: #F9DC5C;
	--ion-color-warning-rgb: 249,220,92;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #dbc251;
	--ion-color-warning-tint: #fae06c;

	--ion-color-danger: #EA1D24;
	--ion-color-danger-rgb: 234,29,36;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ce1a20;
	--ion-color-danger-tint: #ec343a;

	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34,36,40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	--ion-color-medium: #283845;
	--ion-color-medium-rgb: 40,56,69;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #23313d;
	--ion-color-medium-tint: #3e4c58;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-item-background: #d7d8da;

	--horizontal-padding: 15px;
	--header-main: #F9F2D4;
	--header-accent: #E7E8EC;

	--ion-background-color: var(--ion-color-primary);
	--ion-font-family: 'Montserrat';


	--header-height: clamp(80px, 2.5vw, 110px);
	--content-vertical-padding: 15px;
	--form-top-padding: 20px;
	--form-bottom-padding: 10px;
	--content-height: calc(100vh - var(--header-height));
	--content-inner-height: calc(var(--content-height) - var(--content-vertical-padding) - var(--content-vertical-padding));
	--content-inner-height-actual: calc(var(--content-inner-height)  - var(--content-vertical-padding) - var(--content-vertical-padding));
	--image-height: calc(var(--content-inner-height-actual) / 3);
	--question-height: calc(var(--content-inner-height-actual) / 4);
	
	--form-height: calc(var(--content-inner-height-actual) - var(--image-height));
	--form-inner-height: calc(var(--form-height));
	
	--options-total-height: calc(var(--form-inner-height) - var(--question-height) - var(--form-top-padding) - var(--form-bottom-padding));
	--option-height: calc(var(--options-total-height) / 4);
	--option-line-height: calc(var(--option-height) * 0.4px);
	--option-radius: 10px;
	--option-font-size: clamp(15px, calc((var(--option-height) * 0.4) - 2px), 30px);
	--option-vertical-margin: clamp(2px, 1vh, 8px);
	--option-padding: 0 clamp(10px,2vh,30px);/*clamp(10px,1vh,15px) clamp(10px,2vh,30px);*/
}

ion-toolbar {
	--padding-start: var(--horizontal-padding);
	--padding-end: var(--horizontal-padding);
	--background: linear-gradient(to left, var(--header-accent), var(--header-main));
	--min-height: clamp(80px, 2.5vw, 110px);
}

ion-app {
	--background: var(--ion-color-primary);
}

ion-badge {
	padding: 6px 10px;
	margin: 0 3px;
}
